<template>
  <h1>404 - Страница не найдена</h1>
</template>

<script>
export default {
  name: "Page404",
};
</script>

<style scoped></style>
